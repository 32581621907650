import React, { useContext, useMemo } from "react";
import { Html } from "@react-three/drei";
import { imageToBase64 } from "../_util";
import { Scene } from "./_three_components/_index.js";
import { SocketContext } from "./_contexts/_websocket";
import { Glb, Obj, Pcd, Ply, Urdf } from "./_three_components/_data_loaders";
import {
  Box,
  Capsule,
  Circle,
  Cone,
  Cylinder,
  Dodecahedron,
  Edges,
  Extrude,
  Icosahedron,
  Lathe,
  Octahedron,
  Plane,
  Polyhedron,
  Ring,
  Shape,
  Sphere,
  Tetrahedron,
  Torus,
  TorusKnot,
  Tube,
  Wireframe,
} from "./_three_components/_primitives/_primitives";
import { Gripper, SkeletalGripper } from "./_three_components/_components";
import { Movable, Pivot } from "./_three_components/_controls/_movables";
import { Camera, CameraHelper } from "./_three_components/_camera";
import { BBox } from "./_three_components/_primitives/_bbox";
import { CameraView } from "./_three_components/_camera_view";
import {
  AmbientLight,
  DirectionalLight,
  PointLight,
  SpotLight,
} from "./_three_components/_lighting";
import { Frustum } from "./_three_components/_frustum";
import { Render, RenderLayer } from "./_nerf_components/_view";
import { Markdown } from "./_markdown/_markdown";
import { AutoScroll } from "./_chat/_autoscroll";
import {PointCloud} from "./_three_components/_primitives/_pointclound";
import {TriMesh} from "./_three_components/_primitives/_trimesh";
import {Gamepad} from "./_three_components/_controls/_gamepad";
import {Hands} from "./_three_components/_controls/_hands";

export function Button({ sendMsg, _key: key, value, ...props }) {
  return (
    <button onClick={(e) => sendMsg({ etype: "CLICK", key })} {...props}>
      {value}
    </button>
  );
}

export function Slider({
  sendMsg,
  _key: key,
  value: defaultValue,
  children,
  ...props
}) {
  const [value, setValue] = React.useState(defaultValue || 0);
  return (
    <>
      <input
        type="range"
        value={value}
        onMouseUp={(e) => {
          sendMsg({ etype: "SET", key, value: e.target.value });
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        {...props}
      />
      <span>{value}</span>
    </>
  );
}

export function Img({ sendMsg, _key: key, children, ...props }) {
  // remove children from the props.
  return (
    <img
      className="input-image"
      onClick={(e) => {
        console.log("click on image");
        e.preventDefault();
        e.stopPropagation();
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        sendMsg({
          etype: "CLICK",
          key,
          value: { x: x, y: y, w: rect.width, h: rect.height },
        });
      }}
      {...props}
    />
  );
}

export function Input({
  sendMsg: _,
  _key: key,
  value: _value,
  children,
  style,
  // buttonStyle = {},
  textareaStyle = {},
  placeholder,
  defaultValue,
  // todo: allow multiple to choose from.
  defaultValues,
  clearOnSubmit ,
  // todo: allow line break with enter.
  ...props
}) {
  const [value, setValue] = React.useState(_value, [_value]);
  const { sendMsg } = useContext(SocketContext);
  const onChange = useMemo(() => (e) => {
    setValue(e.target.value);
  });
  const onKeyUp = useMemo(() => (e) => {
    // info: choose default
    if (e.keyCode == 39 && !value) {
      // on right arrow key:
      if (!defaultValue) return;
      e.preventDefault();
      setValue(defaultValue);
    }
    // info: submit
    if (e.keyCode == 13 && e.shiftKey == false) {
      // on enter:
      e.preventDefault();
      sendMsg({ etype: "INPUT", key, value });
      if (clearOnSubmit) setValue("");
    }
  });
  return (
    <form
      style={{
        flex: "0 0 auto",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
      {...props}
    >
      <textarea
        type="text"
        placeholder={
          placeholder ||
          `"${defaultValue}" [ press ➡ for default, ⏎ to submit ]`
        }
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        style={{
          flex: "1 1 auto",
          height: "100%",
          width: "100%",
          padding: "10px",
          // margin: "5px 0",
          marginTop: "5px",
          border: "1.5px solid #eee",
          borderRadius: "5px",
          ...textareaStyle,
        }}
      />
    </form>
  );
}

// <input type="submit" value="Submit" style={buttonStyle} />

export function ImageUpload({ sendMsg, _key: key, label, ...props }) {
  const [file, setFile] = React.useState(null);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        imageToBase64(file).then((base64) => {
          // chop off the data:image/png;base64, part
          const value = base64.slice(22);
          sendMsg({ etype: "UPLOAD", key, value: value });
        });
      }}
    >
      <label htmlFor="file">{label} </label>
      <input type="file" onChange={(e) => setFile(e.target.files[0])} />
      <input type="submit" value="Submit" />
    </form>
  );
}

export function Div({ sendMsg, _key: key, ...props }) {
  return <div key={key} {...props} />;
}

export function Text({ sendMsg, _key: key, text, ...props }) {
  return <span {...props}>{text}</span>;
}

// prettier-ignore
export const comp_list = {
  Slider, Input, Text, Img, Button, ImageUpload, Div,
  // parts of the three-js scene components, can be written as an extension - Ge
  Scene, Ply, Obj, Pcd, Glb, Gltf: Glb,
  PointCloud, TriMesh,
  Urdf, Gripper, SkeletalGripper, Pivot, Movable,
  Gamepad, Hands,
  Frustum,
  Box,
  Capsule,
  Circle,
  Cone,
  Cylinder,
  Dodecahedron,
  Edges,
  Extrude,
  Icosahedron,
  Lathe,
  Octahedron,
  Plane,
  Polyhedron,
  Ring,
  Shape,
  Sphere,
  Tetrahedron,
  Torus,
  TorusKnot,
  Tube,
  Wireframe,
  PointLight, DirectionalLight, AmbientLight, SpotLight,
  CameraView, Camera, Html, CameraHelper, BBox,
  Render, RenderLayer,
  AutoScroll,
  Markdown,
};
