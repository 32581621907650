import React, {Hands as BareHands} from "@react-three/xr"
import {SocketContext} from "../../_contexts/_websocket";
import {useContext} from "react";


export const Hands = ({_key: key, ...rest}) => {
  const {sendMsg} = useContext(SocketContext);
  const onChange = useCallback((gamepads) => {
    const { axes, buttons, connected, id, index, mapping, timestamp } =
      gamepads[0];
    sendMsg({
      etype: "HANDS",
      key,
      value: { axes, buttons: buttons.map((b) => b.value) },
    });
  }, [sendMsg]);
  // you can add custom GLB models to the hands
  return <BareHands key={key} {...rest}/>;
};
