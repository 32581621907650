import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef,} from "react";
import {invalidate, useFrame, useThree} from "@react-three/fiber";
import {CameraHelper as CH, Euler, Matrix4, Quaternion, Vector3} from "three";
import {
  Camera as MatrixCamera,
  Html,
  Line,
  OrbitControls,
  OrthographicCamera,
  PerspectiveCamera,
  Plane,
  Sphere,
  useHelper,
  useTexture,
} from "@react-three/drei";
import _throttle from "lodash.throttle";
import {useControls} from "leva";
import {parse} from "query-string";
import {document} from "browser-monads";
import {SocketContext} from "../_contexts/_websocket"; // import {Line} from "./_primitives";
// import {Line} from "./_primitives";

const CAMERA_TYPES = {
  PerspectiveCamera,
  OrthographicCamera,
  Camera: MatrixCamera,
};

function equals(aArr, bArr) {
  for (let i = 0; i < aArr.length; i++) {
    if (aArr[i] !== bArr[i]) return false;
  }
  return true;
}

// note: to be deprecated
// note: also, does not support orthographic camera
export function CameraHelper({
  _key = null,
  type = "PerspectiveCamera",
  // not used
  label = null,

  // in degrees, according to three.js convention
  // https://threejs.org/docs/index.html#api/en/cameras/PerspectiveCamera.fov
  position = null,
  rotation = null,
  matrix = null,
  aspect = 4 / 3,
  focus = 10,
  fov = 50,
  near = 0.1,
  far = 0.2,
  // only applies to the camera cone, for presentation
  scale = 1,

  focalLength = 0.035,
  showUp = true,
  showFrustum = true,
  showFocalPlane = true,
  showImagePlane = false,
  src = null,

  // colors for the helper
  colorOrigin = 0xff0000,
  colorFrustum = 0xffaa00,
  colorCone = 0xff0000,
  colorFocalPlane = "white",
  colorUp = 0x00aaff,
  colorTarget = "green",
  colorCross = 0x333333,

  children,
  sendMsg,
  ...props
}) {
  const cache = useMemo(
    () => ({
      raw: null,
      matrix: new Matrix4(),
      position: new Vector3(0, 0, 0),
      rotation: new Euler(0, 0, 0),
      quaternion: new Quaternion(0, 0, 0, 0),
      scale: new Vector3(0, 0, 0),
    }),
    []
  );

  const group = useRef();

  useFrame(() => {
    if (!group.current) return;

    if (matrix) {
      if (cache.raw && equals(cache.raw, matrix)) return;

      cache.raw = matrix;
      cache.matrix.fromArray(matrix);
      cache.matrix.decompose(
        group.current.position,
        cache.quaternion,
        cache.scale
      );
      group.current.rotation.setFromQuaternion(cache.quaternion);
    } else if (position && rotation) {
      group.current.position.set(...position);
      group.current.rotation.set(...rotation);
    }
  });

  const tan = Math.tan((fov / 360) * Math.PI);

  const cy = tan * focus;
  const cx = aspect * cy;

  const cy_focal = tan * focalLength;
  const cx_focal = aspect * cy_focal;

  const cy_near = tan * near;
  const cx_near = aspect * cy_near;

  const cy_far = tan * far;
  const cx_far = aspect * cy_far;

  const image = src ? useTexture(src) : null;

  return (
    <group ref={group} key={_key}>
      <Sphere
        key="origin"
        position={[0, 0, 0]}
        material-color={colorOrigin}
        scale={scale * 0.002}
      />
      {showUp ? (
        <Line
          key="up"
          scale={scale}
          points={[
            [-cx_focal * 0.6, cy_focal * 1.05, -focalLength],
            [cx_focal * 0.6, cy_focal * 1.05, -focalLength],
            [0, cx_focal * 1.2, -focalLength],
            [-cx_focal * 0.6, cy_focal * 1.05, -focalLength],
          ]}
          color={colorUp}
          lineWidth={1}
        />
      ) : null}
      {showImagePlane
        ? [
            <Line
              key="image-plane-cone"
              scale={scale}
              // args={[- focalLength, cy_focal, 4, 1]}
              points={[
                [0, 0, 0],
                [-cx_focal, -cy_focal, -focalLength],
                [0, 0, 0],
                [cx_focal, -cy_focal, -focalLength],
                [0, 0, 0],
                [cx_focal, cy_focal, -focalLength],
                [0, 0, 0],
                [-cx_focal, cy_focal, -focalLength],
              ]}
              color={colorCone}
              lineWidth={1}
              segments
            />,
            <Line
              key="image-plane"
              scale={scale}
              points={[
                [-cx_focal, -cy_focal, -focalLength],
                [cx_focal, -cy_focal, -focalLength],
                [cx_focal, cy_focal, -focalLength],
                [-cx_focal, cy_focal, -focalLength],
                [-cx_focal, -cy_focal, -focalLength],
              ]}
              color={colorFocalPlane}
              lineWidth={1}
            />,
          ]
        : null}
      {showFrustum
        ? [
            <Line
              key="near-plane"
              points={[
                [-cx_near, -cy_near, -near],
                [cx_near, -cy_near, -near],
                [cx_near, cy_near, -near],
                [-cx_near, cy_near, -near],
                [-cx_near, -cy_near, -near],
              ]}
              color={colorFrustum}
              lineWidth={1}
            />,
            <Line
              key="cone"
              points={[
                [0, 0, 0],
                [-cx_near, -cy_near, -near],
                [0, 0, 0],
                [cx_near, -cy_near, -near],
                [0, 0, 0],
                [cx_near, cy_near, -near],
                [0, 0, 0],
                [-cx_near, cy_near, -near],
                [0, 0, 0],
                [-cx_near, -cy_near, -near],
              ]}
              color={colorCone}
              lineWidth={1}
              segments
            />,
            <Line
              key="far-plane"
              points={[
                [-cx_far, -cy_far, -far],
                [cx_far, -cy_far, -far],
                [cx_far, cy_far, -far],
                [-cx_far, cy_far, -far],
                [-cx_far, -cy_far, -far],
              ]}
              color={colorFrustum}
              lineWidth={1}
            />,
            <Line
              key="far-plane-frustum"
              points={[
                [-cx_near, -cy_near, -near],
                [-cx_far, -cy_far, -far],
                [cx_near, -cy_near, -near],
                [cx_far, -cy_far, -far],
                [cx_near, cy_near, -near],
                [cx_far, cy_far, -far],
                [-cx_near, cy_near, -near],
                [-cx_far, cy_far, -far],
              ]}
              color={colorFrustum}
              lineWidth={1}
              segments
            />,
          ]
        : null}
      {showFocalPlane
        ? [
            <Sphere
              key="focus"
              position={[0, 0, -focus]}
              material-color={colorTarget}
              scale={0.2}
            />,
            <Line
              key="focal-plane"
              points={[
                [-cx, -cy, -focus],
                [cx, -cy, -focus],
                [cx, cy, -focus],
                [-cx, cy, -focus],
                [-cx, -cy, -focus],
              ]}
              color={colorFocalPlane}
              lineWidth={1}
            />,
            <Line
              key="cross-hair"
              points={[
                [0, cy, -focus],
                [0, -cy, -focus],
                [cx, 0, -focus],
                [-cx, 0, -focus],
              ]}
              color={colorCross}
              lineWidth={1}
              segments
            />,
          ]
        : null}
      {src
        ? [
            <Plane position={[0, 0, -focalLength]}>
              {src ? (
                <meshBasicMaterial attach="material" map={image} side={true} />
              ) : null}
            </Plane>,
          ]
        : null}
      {label ? (
        <Html
          className="label"
          tag="span"
          style={{
            display: "inline-block",
            cursor: "pointer",
            userSelect: "none",
            borderRadius: "0.25rem",
            padding: "0.25rem 0.5rem 0.25rem 0.5rem ",
            background: "white",
            color: "black",
            width: "max-content",
            maxWidth: "200px",
            height: "fit-content",
          }}
        >
          {label}
        </Html>
      ) : null}
    </group>
  );
}

export function Camera({
  _ref,
  animate,
  type = "PerspectiveCamera",
  // not used
  label = null,
  near = 0.1,
  far = 0.2,
  fov = 75,
  src = null,
  position = null,
  rotation = null,
  matrix = null,
  makeDefault = false,
  ..._props
}) {
  const ref = React.useRef();

  useHelper(ref, CH, []);

  let props;

  const t = useMemo(() => new Vector3(0, 0, 0));
  const cache = useMemo(
    () => ({
      matrix: new Matrix4(0, 0, 0),
      position: new Vector3(0, 0, 0),
      rotation: new Euler(0, 0, 0),
      quaternion: new Quaternion(0, 0, 0, 0),
      scale: new Vector3(0, 0, 0),
    }),
    []
  );

  useEffect(() => {
    if (ref.current && matrix) {
      const cam = ref.current;
      cache.matrix.fromArray(matrix);
      cache.matrix.decompose(cam.position, cache.quaternion, cam.scale);
      cam.rotation.setFromQuaternion(cache.quaternion);
      cam.worldMatrixNeedsUpdate = true;
      cam.updateMatrixWorld(true);
      cam.updateWorldMatrix(true, true);
      invalidate();
    }
  }, [ref.current, ...(matrix || [])]);

  useEffect(() => {
    const cam = ref.current;
    cam.near = near;
    cam.far = far;
    cam.fov = fov;
  }, [ref.current, fov, near, far]);

  useFrame((state) => {
    if (!animate) return;
    if (typeof animate === "function") {
      animate(ref.current, state);
      ref.current.lookAt(t);
    } else {
      // go to the target
      console.log("animated camera motion is not implemented yet");
    }
  });

  const Component = CAMERA_TYPES[type];

  return (
    <Component
      ref={ref}
      position={position || undefined}
      rotation={rotation || undefined}
      makeDefault={makeDefault}
      {...props}
      {..._props}
    >
      {label ? (
        <Html
          className="label"
          // transform
          // occlude
          tag="span"
          style={{
            display: "inline-block",
            // cursor: 'pointer',
            // userSelect: 'none',
            borderRadius: "0.25rem",
            padding: "0.25rem 0.5rem 0.25rem 0.5rem ",
            background: "white",
            color: "black",
            width: "max-content",
            maxWidth: "200px",
            height: "fit-content",
          }}
        >
          {label}
        </Html>
      ) : null}
    </Component>
  );
}

export function WhenCameraMoves({ onMove = null }) {
  const mat = useMemo(() => new Matrix4());
  const throttled = useCallback(
    _throttle(
      ({ camera }) => {
        if (typeof onMove !== "function") return;
        if (mat.equals(camera.matrixWorld)) return;
        const a = mat.toArray();
        const b = camera.matrixWorld.toArray();
        // make a variable for the L1 distance between the two matrices
        const d = a.reduce((acc, v, i) => acc + Math.abs(v - b[i]), 0);
        if (d < 0.0005) return;
        // use copy, because camera matrix is updated in-place.
        mat.copy(camera.matrixWorld);
        // prettier-ignore
        const {
          name, type, far, near, focus,
          aspect, fov,
          up, position, rotation, matrix, matrixWorld, projectionMatrix,
        } = camera;
        // prettier-ignore
        onMove({
          type, aspect, far, focus, fov,
          matrix: matrix.elements,
          matrixWorld: matrixWorld.elements,
          name, near,
          position: position.toArray(),
          rotation: rotation.toArray(),
          projectionMatrix: projectionMatrix.elements,
          up,
        });
      },
      16,
      { leading: true, trailing: true }
    ),
    [onMove]
  );
  useFrame(throttled, -1);
}

// note: experimental-delete
export function SmoothCamera() {
  const perspectiveCam = useRef();
  const orthoCam = useRef();
  const { get, set } = useThree(({ get, set }) => ({ get, set }));

  const { type, ...controls } = useControls("Camera Control", {
    type: { value: "Perspective", options: ["Perspective", "Orthographic"] },
    position: { value: [0, 2, 10], step: 0.1 },
    // prettier-ignore
    near: 0.1,
    far: 100,
    // orthographic
    fov: 50,
    zoom: 100,
  });

  useEffect(() => {
    if (type === "Perspective") {
      set({ camera: perspectiveCam.current });
    } else {
      set({ camera: orthoCam.current });
    }
  }, [get, set, type]);

  return (
    <>
      <PerspectiveCamera
        name="3d"
        ref={perspectiveCam}
        // position={[0, 2, 10]}
        fov={50}
        near={controls.near}
        far={controls.far}
      />
      <OrthographicCamera
        name="2d"
        ref={orthoCam}
        // position={[0, 2, 0]}
        zoom={100}
        near={controls.near}
        far={controls.far}
        left={window.innerWidth / -2}
        right={window.innerWidth / 2}
        top={window.innerHeight / 2}
        bottom={window.innerHeight / -2}
      />
    </>
  );
}

function zoomToFov(zoom, orbit_distance, viewHeight) {
  // console.log("zoom to fov", zoom, orbit_distance, viewHeight);
  // when zoom is 1, should capture [-1, 1] in the world box
  const physicalViewHeight = viewHeight / zoom;
  const fov =
    (360 / Math.PI) * Math.atan(physicalViewHeight / (2 * orbit_distance));
  return fov;
}

function fovToZoom(fov, orbit_distance, viewHeight) {
  // console.log("fov to zoom", fov, orbit_distance, viewHeight);
  const physicalViewHeight =
    2 * orbit_distance * Math.tan((fov / 360) * Math.PI);
  const zoom = viewHeight / physicalViewHeight;
  return zoom;
}

export function KeyboardControls({ parent, controlsRef, panSpeed = 0.016 }) {
  // const { camera } = useThree();
  useLayoutEffect(() => {
    // React-fiber calls OrbitControls.dispose automatically.
    const ctrl = controlsRef.current;
    if (!ctrl) return;
    const camera = controlsRef.current.object;

    // const key_bank = {};

    const keyDown = (function () {
      // closure, to avoid re-instancing this dummy variable.
      const v = new Vector3();

      return function (e) {
        let moved = false;

        // todo: Use FOV scale to determine pan speed. Use fraction of FOV.
        // note: use reference, won't need to regenerate this function
        const distance = camera.position.distanceTo(
          controlsRef?.current.target
        );
        const adjusted =
          panSpeed * (distance * Math.tan((Math.PI * camera.fov) / 360));
        const clipped = Math.max(adjusted, 0.001);
        const speed = e.shiftKey ? clipped * 10 : clipped;

        if (e.code === "KeyW") {
          v.setFromMatrixColumn(ctrl.object.matrix, 0);
          v.crossVectors(ctrl.object.up, v);
          v.normalize();
          v.multiplyScalar(speed);
          ctrl.target.add(v);
          camera.position.add(v);
          moved = true;
        }
        if (e.code === "KeyS") {
          v.setFromMatrixColumn(ctrl.object.matrix, 0);
          v.crossVectors(ctrl.object.up, v);
          v.normalize();
          v.multiplyScalar(speed);
          v.negate();
          ctrl.target.add(v);
          camera.position.add(v);
          moved = true;
        }
        if (e.code === "KeyA") {
          v.copy(ctrl.target);
          v.sub(ctrl.object.position);
          v.crossVectors(ctrl.object.up, v);
          v.normalize();
          v.multiplyScalar(speed);
          ctrl.target.add(v);
          camera.position.add(v);
          moved = true;
        }
        if (e.code === "KeyD") {
          v.copy(ctrl.target);
          v.sub(ctrl.object.position);
          v.crossVectors(ctrl.object.up, v);
          v.normalize();
          v.multiplyScalar(speed);
          v.negate();
          ctrl.target.add(v);
          camera.position.add(v);
          moved = true;
        }
        if (e.code === "KeyE") {
          v.set(0, speed, 0);
          ctrl.target.add(v);
          camera.position.add(v);
          moved = true;
        }
        if (e.code === "KeyQ") {
          v.set(0, -speed, 0);
          ctrl.target.add(v);
          camera.position.add(v);
          moved = true;
        }
        if (moved) invalidate();
      };
    })();

    const keyUp = (e) => {
      // key_bank[e.code] = false;
      // if (e.code === "Space") key_bank.clear();
    };

    if (parent.current) {
      let el = parent.current;
      /** Important **
       to use canvas component as the event target, you need to
       pass in a tabindex=1 because canvas is not a focusable element
       on start.
       See this: https://stackoverflow.com/a/32936969/1560241
       Setting this in react, on the component parent does not work. */
      el.tabIndex = 1;

      el.addEventListener("keydown", keyDown, true);
      el.addEventListener("keydown", keyUp, true);

      return () => {
        el.removeEventListener("keydown", keyDown);
        el.removeEventListener("keydown", keyUp);
      };
    }
  }, [controlsRef.current, parent.current, panSpeed]);
}

export function OrbitCamera({
  parent = null,
  onChange,
  panSpeed = 1, // roughly 1 unit per second
  fov = 75,
  zoom = 1,
  position = null,
  near = null,
  far = null,
  initPosition = [-0.5, 0.75, 0.8],
  ...props
}) {
  const controlsRef = useRef();
  const camRef = useRef();
  const orthoRef = useRef();
  const perspRef = useRef();

  const queries = useMemo(() => parse(document.location.search));
  const initialPosition = queries.camPosition
    ? queries.camPosition?.split(",").map(Number)
    : initPosition;
  const [controlled, setControls] = useControls("Camera Control", () => ({
    zoom: {
      label: "Zoom (px)",
      value: Number(queries.zoom) || zoom || 1,
      // in pixels,
      step: 0.001,
      min: 0.001,
      pad: 4,
      // no max, because it can get pretty large.
      // max: 5000,
    },
    fov: {
      label: "Fov°",
      value: Number(queries.fov) || fov || 75,
      step: 0.1,
      min: 0.1,
      max: 220,
    },
  }));
  const { ctype, camInitPosition, ...ctrls } = useControls("Camera Control", {
    ctype: {
      value: "Perspective",
      options: ["Perspective", "Orthographic"],
      label: "Cam Type",
    },
    camInitPosition: initialPosition,
    zoomSpeed: 1.0,
    // perspective
    near: {
      label: "Near (cm)",
      value: parseFloat(queries.near) || near || 0.01,
      min: 0.1,
      max: 1000,
      step: 0.1,
    },
    far: {
      label: "Far (m)",
      value: parseFloat(queries.far) || far || 200,
      min: 0.1,
      max: 1000,
      step: 0.1,
    },
    // orthographic
    panSpeed: {
      value: Number(queries.panSpeed) || panSpeed || 1,
      pad: 5,
      step: 1,
    },
  });

  const { set } = useThree(({ get, set }) => ({ get, set }));

  // const viewHeight = parent.current?.clientHeight * gl.getPixelRatio();
  // looks like the view is in the abstract pixels, not the physical pixels.
  const viewHeight = parent.current?.clientHeight;
  const viewWidth = parent.current?.clientWidth;

  useLayoutEffect(() => {
    if (!set) return;

    const currentPos =
      camRef.current?.position || new Vector3(...camInitPosition);
    let orbit_distance;
    if (camRef.current) {
      const target = controlsRef.current.target;
      orbit_distance = camRef.current.position.distanceTo(target);
    }
    if (ctype === "Perspective") {
      const zoom = camRef?.current?.zoom;
      perspRef.current.position.copy(currentPos);
      camRef.current = perspRef.current;
      // place here to avoid rance condition
      controlsRef.current.object = camRef.current;
      if (!!zoom) {
        const fov = zoomToFov(zoom, orbit_distance, viewHeight);
        camRef.current.fov = fov;
        camRef.current.updateProjectionMatrix();
        setControls({ fov });
      }
    } else if (ctype === "Orthographic") {
      const fov = camRef?.current?.fov;
      orthoRef.current.position.copy(currentPos);
      camRef.current = orthoRef.current;
      // place here to avoid rance condition
      controlsRef.current.object = camRef.current;
      if (!!fov) {
        camRef.current.near = -camRef.current.far;
        // want to write so that it is the size of the field of view at the target location.
        // distance to target
        const zoom = fovToZoom(fov, orbit_distance, viewHeight);
        camRef.current.zoom = zoom;
        camRef.current.updateProjectionMatrix();
        setControls({
          zoom: viewHeight / zoom,
        });
      } else {
        console.warn(`camera tpe ${ctype} is not defined`);
      }
    }
    set({ camera: camRef.current });
  }, [ctype]);

  function triggerRender() {
    const defaultCamera = controlsRef.current.object;
    if (!defaultCamera) return;

    const orbit_distance = defaultCamera.position.distanceTo(
      controlsRef.current.target
    );

    // prettier-ignore
    const {
      type,
      near, far, matrix, position, rotation,
      up, fov, focus, aspect,
      left, top, bottom, right,
    } = defaultCamera;

    if (typeof onChange !== "function") return;
    if (type === "PerspectiveCamera") {
      // prettier-ignore
      onChange({
        type,
        near, far,
        matrix: matrix.elements,
        position: position.toArray(),
        rotation: rotation.toArray(),
        up: up.toArray(),
        fov, focus, aspect,
        orbit_distance,
      });
    } else if (type === "OrthographicCamera") {
      // prettier-ignore
      onChange({
        type,
        // orthographic camera does not have aspect
        near, far,
        matrix: matrix.elements,
        position: position.toArray(),
        rotation: rotation.toArray(),
        up: up.toArray(),
        // use the camera zoom instead
        aspect: (right - left) / (top - bottom),
        zoom: viewHeight / defaultCamera.zoom,
        left, top, bottom, right,
        // needed to compute fov
        orbit_distance,
      });
    }
  }

  const { uplink } = useContext(SocketContext);
  // register camera update event
  useEffect(
    () => uplink.subscribe("CAMERA_UPDATE", (msg) => triggerRender()),
    []
  );

  const matCache = useMemo(() => ({ matrix: "" }), []);

  // The change event in the OrbitControl with Orthographic camera has a bug.
  const handler = useCallback(() => {
    const camera = controlsRef.current?.object;
    if (!camera) return;
    const newMat = JSON.stringify([
      camera.zoom,
      camera.fov,
      camera.position.toArray(),
      camera.rotation.toArray(),
    ]);
    if (matCache.matrix !== newMat) triggerRender();
    matCache.matrix = newMat;
  }, [camRef.current, onChange, viewHeight, setControls]);

  // can probably simplify.
  useLayoutEffect(
    () => triggerRender(),
    [
      controlsRef.current,
      ctrls.near,
      ctrls.far,
      controlled.fov,
      controlled.zoom,
      viewHeight,
      viewWidth,
      setControls,
      onChange,
      // ...(up || []),
      ...(position || []),
    ]
  );

  return (
    <>
      <OrbitControls
        ref={controlsRef}
        makeDefault
        enableDamping={false}
        enablePan
        screenSpacePanning={true}
        onChange={handler}
        reverseOrbit={true}
        maxPolarAngle={(135 / 180) * Math.PI}
        minPolarAngle={(0 / 180) * Math.PI}
        maxZoom={Infinity}
        maxDistance={Infinity}
        zoomSpeed={ctrls.zoomSpeed}
      />
      <PerspectiveCamera
        key="perspective"
        makedefault
        ref={perspRef}
        fov={controlled.fov}
        near={ctrls.near}
        far={ctrls.far}
      />
      <OrthographicCamera
        key="orthographic"
        makedefault
        ref={orthoRef}
        zoom={viewHeight / controlled.zoom}
        near={ctrls.near}
        far={ctrls.far}
      />
      <KeyboardControls
        parent={parent}
        controlsRef={controlsRef}
        panSpeed={ctrls.panSpeed / 12_00}
      />
    </>
  );
}
