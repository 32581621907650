import React, { useContext } from "react";
import { useGamepads } from "react-gamepads";
import { SocketContext } from "../../_contexts/_websocket";

export const Gamepad = ({ ...rest }) => {
  const { sendMsg } = useContext(SocketContext);
  useGamepads((gamepads) => {
    const { axes, buttons, connected, id, index, mapping, timestamp } =
      gamepads[0];
    sendMsg({
      etype: "GAMEPADS",
      // key: There is no key for this component
      value: { axes, buttons: buttons.map((b) => b.value) },
    });
  });
  return <></>;
};
