import React, {useLayoutEffect, useMemo, useRef} from "react";
import {Euler, Matrix4, Quaternion, Vector3} from "three";
import {Camera as MatrixCamera, Line, OrthographicCamera, PerspectiveCamera, Sphere,} from "@react-three/drei";
// import {Line} from "./_primitives";

const CAMERA_TYPES = {
  PerspectiveCamera,
  OrthographicCamera,
  Camera: MatrixCamera,
};

function equals(aArr, bArr) {
  for (let i = 0; i < aArr.length; i++) {
    if (aArr[i] !== bArr[i]) return false;
  }
  return true;
}

export function Frustum({
  _key = null,
  _ref = null,
  type = "PerspectiveCamera",
  // not used
  label = null,

  // in degrees, according to three.js convention
  // https://threejs.org/docs/index.html#api/en/cameras/PerspectiveCamera.fov
  position = null,
  rotation = null,
  matrix = null,
  aspect = 4 / 3,
  focus = 10,
  fov = 50,
  near = 0.1,
  far = 0.2,
  // only applies to the camera cone, for presentation
  scale = 1,

  focalLength = 0.035,
  showUp = true,
  showFrustum = true,
  showFocalPlane = true,
  showImagePlane = false,
  src = null,

  // colors for the helper
  colorOrigin = 0xff0000,
  colorFrustum = 0xffaa00,
  colorCone = 0xff0000,
  colorFocalPlane = "white",
  colorUp = 0x00aaff,
  colorTarget = "green",
  colorCross = 0x333333,

  children,
  sendMsg,
  ...props
}) {
  const cache = useMemo(
    () => ({
      raw: null,
      matrix: new Matrix4(),
      position: new Vector3(0, 0, 0),
      rotation: new Euler(0, 0, 0),
      quaternion: new Quaternion(0, 0, 0, 0),
      scale: new Vector3(0, 0, 0),
    }),
    []
  );

  let group = useRef();
  if (!!_ref) group = _ref || group;

  useLayoutEffect(() => {
    if (!group.current) return;

    if (matrix) {
      if (cache.raw && equals(cache.raw, matrix)) return;

      cache.raw = matrix;
      cache.matrix.fromArray(matrix);
      cache.matrix.decompose(
        group.current.position,
        cache.quaternion,
        cache.scale
      );
      group.current.rotation.setFromQuaternion(cache.quaternion);
    } else if (position && rotation) {
      group.current.position.set(...position);
      group.current.rotation.set(...rotation);
    }
  }, [fov, focus, near, far]);

  const all_points = useMemo(() => {
    const tan = Math.tan((fov / 360) * Math.PI);

    const cy = tan * focus;
    const cx = aspect * cy;

    const cy_focal = tan * focalLength;
    const cx_focal = aspect * cy_focal;

    const cy_near = tan * near;
    const cx_near = aspect * cy_near;

    const cy_far = tan * far;
    const cx_far = aspect * cy_far;

    // const image = src ? useTexture(src) : null;

    return {
      up: [
        [-cx_focal * 0.6, cy_focal * 1.05, -focalLength],
        [cx_focal * 0.6, cy_focal * 1.05, -focalLength],
        [0, cx_focal * 1.2, -focalLength],
        [-cx_focal * 0.6, cy_focal * 1.05, -focalLength],
      ],
      // prettier-ignore
      imagePlaneCone: [
        [0, 0, 0], [-cx_focal, -cy_focal, -focalLength],
        [0, 0, 0], [cx_focal, -cy_focal, -focalLength],
        [0, 0, 0], [cx_focal, cy_focal, -focalLength],
        [0, 0, 0], [-cx_focal, cy_focal, -focalLength],
      ],
      imagePlane: [
        [-cx_focal, -cy_focal, -focalLength],
        [cx_focal, -cy_focal, -focalLength],
        [cx_focal, cy_focal, -focalLength],
        [-cx_focal, cy_focal, -focalLength],
        [-cx_focal, -cy_focal, -focalLength],
      ],
      nearPlane: [
        [-cx_near, -cy_near, -near],
        [cx_near, -cy_near, -near],
        [cx_near, cy_near, -near],
        [-cx_near, cy_near, -near],
        [-cx_near, -cy_near, -near],
      ],
      cone: [
        [0, 0, 0],
        [-cx_near, -cy_near, -near],
        [0, 0, 0],
        [cx_near, -cy_near, -near],
        [0, 0, 0],
        [cx_near, cy_near, -near],
        [0, 0, 0],
        [-cx_near, cy_near, -near],
        [0, 0, 0],
        [-cx_near, -cy_near, -near],
      ],
      farPlane: [
        [-cx_far, -cy_far, -far],
        [cx_far, -cy_far, -far],
        [cx_far, cy_far, -far],
        [-cx_far, cy_far, -far],
        [-cx_far, -cy_far, -far],
      ],
      farPlaneFrustum: [
        [-cx_near, -cy_near, -near],
        [-cx_far, -cy_far, -far],
        [cx_near, -cy_near, -near],
        [cx_far, -cy_far, -far],
        [cx_near, cy_near, -near],
        [cx_far, cy_far, -far],
        [-cx_near, cy_near, -near],
        [-cx_far, cy_far, -far],
      ],
      focalPlane: [
        [-cx, -cy, -focus],
        [cx, -cy, -focus],
        [cx, cy, -focus],
        [-cx, cy, -focus],
        [-cx, -cy, -focus],
      ],
      crossHair: [
        [0, cy, -focus],
        [0, -cy, -focus],
        [cx, 0, -focus],
        [-cx, 0, -focus],
      ],
    };
  }, [fov, focus, aspect, near, far]);

  return (
    <group ref={group} key={_key}>
      {/*<Sphere key="origin" position={[0, 0, 0]} material-color={colorOrigin} scale={scale * 0.002}/>*/}
      {showUp ? (
        <Line
          key="up"
          scale={scale}
          points={all_points.up}
          color={colorUp}
          lineWidth={1}
        />
      ) : null}
      {showImagePlane ? (
        <Line
          key="image-plane-cone"
          scale={scale}
          points={all_points.imagePlaneCone}
          color={colorCone}
          lineWidth={2}
          segments
        />
      ) : null}
      {showImagePlane ? (
        <Line
          key="image-plane"
          scale={scale}
          points={all_points.imagePlane}
          color={colorFocalPlane}
          lineWidth={2}
        />
      ) : null}
      {showFrustum
        ? [
            <Line
              key="near-plane"
              points={all_points.nearPlane}
              color={colorFrustum}
              lineWidth={1}
            />,
            <Line
              key="cone"
              points={all_points.cone}
              color={colorCone}
              lineWidth={1}
              segments
            />,
            <Line
              key="far-plane"
              points={all_points.farPlane}
              color={colorFrustum}
              lineWidth={1}
            />,
            <Line
              key="far-plane-frustum"
              points={all_points.farPlaneFrustum}
              color={colorFrustum}
              lineWidth={1}
              segments
            />,
          ]
        : null}
      {showFocalPlane
        ? [
            <Sphere
              key="focus"
              position={[0, 0, -focus]}
              material-color={colorTarget}
              scale={0.2}
            />,
            <Line
              key="focal-plane"
              points={all_points.focalPlane}
              color={colorFocalPlane}
              lineWidth={1}
            />,
            <Line
              key="cross-hair"
              points={all_points.crossHair}
              color={colorCross}
              lineWidth={1}
              segments
            />,
          ]
        : null}
    </group>
  );
}
