export function findByKey(node, key, extra = []) {
  if (node.key === key) return node;

  let all = ["children", ...extra];

  for (const attr of all) {
    if (!!node[attr]) {
      for (const child of node[attr]) {
        const result = findByKey(child, key, extra);
        if (!!result) return result;
      }
    }
  }
}

// todo: add find parent by key
// todo: remove element from parent
export function removeByKey(node, key, extra = []) {
  let all = ["children", ...extra];

  for (const attr of all) {
    if (!!node[attr]) {
      for (const child of node[attr]) {
        if (child.key === key) return node[attr];
        const index = node[attr].indexOf(child);
        // eslint-disable-next-line no-continue
        if (index < 0) continue;
        node[attr].splice(index, 1);
        // return true to trigger update
        return true;
      }
    }
  }
}

export function addNode(scene, element, parentKey = null) {
  const parent = !!parentKey ? findByKey(scene, parentKey) : scene;
  if (!!parent) {
    if (!parent.children) parent.children = [];
    parent.children.push(element);
    // return true to trigger update
    return true;
  } else {
    if (!scene) return;
    if (!scene.children) scene.children = [];
    scene.children.push(element);
    // return true to trigger update
    return true;
  }
}

export function imageToBase64(img) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(img);
  });
}

export function jpg64ToUri(base64, mtype = "image/jpeg") {
  return `data:${mtype};base64,${base64}`;
}
